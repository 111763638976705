export default {
    name: "loader-full",
    props: {
        isLoading: {
            type: Boolean,
            default() {
                return true;
            },
        },
        showLoadingText: {
            type: Boolean,
            default() {
                return true;
            },
        },
        loadingText: {
            type: String,
            default() {
                return this.$t('general.loading_dots');
            },
        },
    },
    components: {},
    data() {
        return {};
    },
    computed: {},
    mounted() { },
};
