var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FormulateForm',{staticClass:"min-h-full flex-1",scopedSlots:_vm._u([{key:"default",fn:function({ hasErrors }){return [_c('div',{staticClass:"flex flex-col justify-between min-h-full"},[_c('div',{staticClass:"form flex w-full flex-col flex-1"},[_c('FormulateInput',{staticClass:"w-full",style:({
            opacity: _vm.queryData !== 'view' ? 1 : 1,
            cursor: _vm.isFieldSystemProvided
              ? 'not-allowed'
              : _vm.queryData !== 'view'
              ? 'pointer'
              : 'not-allowed',
          }),attrs:{"label":_vm.$t('pages.field_table.field_name'),"disabled":_vm.isFieldSystemProvided || _vm.queryData === 'view',"element-class":(context, classes) => {
              const additionalClasses =
                _vm.queryData !== 'view' && !_vm.isFieldSystemProvided
                  ? 'opacity-100'
                  : 'opacity-50';
              return [`flex-1 min-w-full ${additionalClasses}`].concat(
                classes
              );
            },"placeholder":_vm.$t('pages.field_table.field_name'),"type":"text","validation":"required"},model:{value:(_vm.fieldData.name),callback:function ($$v) {_vm.$set(_vm.fieldData, "name", $$v)},expression:"fieldData.name"}}),_c('FormulateInput',{staticClass:"w-full",style:({
            opacity: _vm.queryData !== 'view' ? 1 : 1,
            cursor: _vm.queryData !== 'view' ? 'pointer' : 'not-allowed',
          }),attrs:{"label":_vm.$t('pages.field_table.field_label'),"disabled":_vm.queryData === 'view',"element-class":(context, classes) => {
              const additionalClasses =
                _vm.queryData !== 'view' ? 'opacity-100' : 'opacity-50';
              return [`flex-1 min-w-full ${additionalClasses}`].concat(
                classes
              );
            },"placeholder":_vm.$t('pages.field_table.field_label'),"type":"text","validation":"required"},model:{value:(_vm.fieldData.label),callback:function ($$v) {_vm.$set(_vm.fieldData, "label", $$v)},expression:"fieldData.label"}}),_c('FormulateInput',{staticClass:"w-full",style:({
            opacity: _vm.queryData !== 'view' ? 1 : 1,
            cursor:
              _vm.isFieldSystemProvided || _vm.isClonedSystemProvided
                ? 'not-allowed'
                : _vm.queryData !== 'view'
                ? 'pointer'
                : 'not-allowed',
          }),attrs:{"options":_vm.entityTypeOptions,"variant":"alt","label":_vm.$t('pages.field_table.entity_type'),"disabled":_vm.queryData === 'view' ||
              _vm.isClonedSystemProvided ||
              _vm.isFieldSystemProvided,"placeholder":_vm.$t('pages.field_table.select_entity_type'),"type":"multi-select","validation":"required","element-class":(context, classes) => {
              const additionalClasses =
                _vm.queryData !== 'view' && !_vm.isClonedSystemProvided
                  ? 'opacity-100'
                  : 'opacity-50';
              return [`flex-1 min-w-full ${additionalClasses}`].concat(
                classes
              );
            },"config":{
            ..._vm.ENTITY_TYPES_DROPDOWN_CONFIG,
            limit: 4,
          }},on:{"input":_vm.handleInput},model:{value:(_vm.entityTypesModel),callback:function ($$v) {_vm.entityTypesModel=$$v},expression:"entityTypesModel"}}),_c('FormulateInput',{staticClass:"w-full testClass",attrs:{"label":_vm.$t('pages.field_table.info_text'),"placeholder":_vm.$t('pages.field_table.info_text'),"type":"rich-text","validation":"required","element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes)},model:{value:(_vm.fieldData.placeholder),callback:function ($$v) {_vm.$set(_vm.fieldData, "placeholder", $$v)},expression:"fieldData.placeholder"}}),_c('FormulateInput',{staticClass:"w-full",attrs:{"label":_vm.$t('pages.field_table.description'),"disabled":(_vm.fieldData && _vm.fieldData.is_restricted) || _vm.queryData == 'view',"element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes),"placeholder":_vm.$t('pages.field_table.description'),"type":"textarea"},model:{value:(_vm.fieldData.description),callback:function ($$v) {_vm.$set(_vm.fieldData, "description", $$v)},expression:"fieldData.description"}})],1),(!_vm.fieldData.is_restricted)?_c('div',{staticClass:"card-actions justify-end"},[_c('Button',{attrs:{"text":_vm.$t('pages.field_table.save'),"type":"primary","disabled":hasErrors},nativeOn:{"click":function($event){return _vm.$emit('saveCreateField')}}})],1):_vm._e()])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }