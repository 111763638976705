<template>
    <div class="text-sm breadcrumbs">
        <ul class="text-sm font-semibold">
            <template v-if="!showOnlyCustomCrumbs">
                <li v-for="breadcrumb in breadcrumbList" :key="breadcrumb.name" class="flex items-center gap-2 text-primary capitalize ">
                    <!-- <font-awesome-icon v-if="crumb.icon" :icon="crumb.icon" /> -->
                   <template v-if="(breadcrumb.clickable && action) || breadcrumb.giveAccess">
                        <a  @click=getBackTo(breadcrumb.goTo) class="underline underline-offset-4">{{ breadcrumb.name }}</a>
                   </template>

                   <template v-else>
                      {{ breadcrumb.name }}
                   </template>

                </li>
            </template>
            <li v-for="breadcrumb in customCrumbs" :key="breadcrumb.name" class="flex items-center gap-2 text-primary capitalize ">
                <!-- <font-awesome-icon v-if="crumb.icon" :icon="crumb.icon" /> -->
                <span class="block  max-w-xs truncate "  :title="breadcrumb.name" >

                    {{ breadcrumb.name }}
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Breadcrumb",
    data() {
        return {
            // customCrumbs: null,
            currentRoute:'',
            action:false
        };
    },
    props: {
        showOnlyCustomCrumbs: {
            type: Boolean,
            default: false,
        },
        customCrumbLists: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        customCrumbs() {
            // if (this.showOnlyCustomCrumbs) {
            return this.customCrumbLists;
            // } else {
            //     return this.$route.meta.customCrumbs || null;
            // }
        },
        breadcrumbList() {
            return this.$route.meta.breadcrumb.map(i=>({
                ...i,
                name: i.tName ? this.$t(i.tName) : i.name,
            }));
        }
    },
    mounted() {
        this.getCurrentLocation()
    },
    methods: {
        routeTo(pRouteTo) {
            if (this.breadcrumbList[pRouteTo].link) this.$router.push(this.breadcrumbList[pRouteTo].link);
        },
        // updateDynamicRoute(){ this.customCrumbs = this.$route.meta.customCrumbs },
        getCurrentLocation(){
         this.currentRoute = window.location.href;
         const parts = this.currentRoute.split('/')
         if( (parts.includes('view') || parts.includes('packages')) && parts.includes('edit')){
         this.action = false
         } else if(parts.includes('view'))
         {
            this.action = true
         }
         
        },
        getBackTo(toPage){
            this.$router.push({ path:toPage });
        }
    },
};
</script>

<style>
li:before {
    color: black;
}
</style>
