<!-- Localized -->
<template>
    <div v-if="open" class="addblocktosection flex-col h-screen w-screen flex items-center justify-center fixed inset-0" @click.stop="closeModal()">
        <div class="h-auto flex-col px-6 py-3 space-y-4 flex items-center justify-center rounded-md" @click.stop>
            <div class="flex space-x-6" style=" height: auto">
                        <!-- {{ listType.listModalTitle || "" }} -->
                <div class="max-h-full w-full  max-w-md  overflow-y-auto flex flex-col">
                    <div class="bg-primary p-2 rounded-t-lg text-white-text font-semibold flex items-center justify-between">
                        {{ $t('pages.field_table.delete_field') }}
                        <button class="bg-white cursor-pointer rounded-full h-6 w-6 justify-center items-center flex" @click="closeModal()">
                            <font-awesome-icon icon="times" class="text-gray-800" />
                        </button>
                    </div>
                    <div class="bg-card-bg rounded-b-lg flex-1 h-full p-5 flex flex-col overflow-y-auto max-w-full">
                        <!-- <div v-if="loadingLists" class="flex justify-center items-center flex-1">
                            <Loader />
                        </div>

                        <ListView v-else :showSearch="true" :listItems="lists" class="max-w-full" :showBorder="false" @handleFilterResult="$emit('handleFilterResult', $event)">
                            <template #item="{ item }">
                                <FieldListItem  :activeElement="activeElement" :data="item" @handleSelect="handleSelect($event)" />
                            </template>
                        </ListView> -->
                        <div class="text-gray-700 mb-3"> <strong>{{ $t('pages.field_table.field_name') }}</strong>: {{field.name}}  </div>
                        <template v-if="usedInDetailsFiltered && usedInDetailsFiltered.length">
                            <label for="#" class="text-gray-500 text-sm  mb-2 block">{{ $t('pages.field_table.this_field_is_used_in') }}</label>
                            <div class="flex space-y-3 flex-col mb-4" >
                                <p class="text-primary cursor-pointer hover:text-primary-focus"
                                v-for="used in usedInDetailsFiltered" :key="used.label+used.count" @click="showDetails(used)">
                                    <span class="mr-2 font-medium" v-text="used.count" />
                                    <span v-text="used.label" />
                                </p>
                            </div>
                        </template>
                        <label for="#" class="text-sm block mb-2">{{ $t('pages.field_table.confirm_delete_selected_fields') }}</label>
                        <div class="flex space-x-6 mb-6">
                            <div class="form-control">
                                <label class="label cursor-pointer">
                                <input type="radio" class="flex-shrink-0 h-5 w-5 text-dvbrandcolor" @change="confirmInput = ''" value="yes" :id="field.id + 'one'"  v-model="confirmDelete" />
                                    <span class="label-text ml-3">{{ $t('general.yes') }}</span> 
                                </label>
                            </div>
                            <div class="form-control">
                                <label class="label cursor-pointer">
                                <input type="radio" class="flex-shrink-0 h-5 w-5 text-dvbrandcolor"  @change="confirmInput = ''" value="no" :id="field.id + 'two'"  v-model="confirmDelete" />
                                    <span class="label-text ml-3">{{ $t('general.no') }}</span> 
                                </label>
                            </div>
                        </div>
                        <template v-if="confirmDelete === 'yes'">
                            <label for="#" class="text-sm block mb-2">{{ $t('pages.field_table.confirm_delete_field_type') }}<code>{{ $t('general.yes') }}</code>{{ $t('general.below') }}</label>
                            <div class="form-control mb-6">
                                <input type="text" :placeholder="$t('pages.field_table.enter_yes_here')"   v-model.trim="confirmInput"  class="form input-bordered input focus:input-primary" />
                            </div>
                        </template>
                        <div class="grid grid-cols-2 gap-x-6 ">
                            <button class="bg-gray-200 hover:bg-gray-300 transition-colors  hover:text-gray-700 text-gray-600 px-8 font-medium py-3.5 rounded-xl " @click="closeModal">{{ $t('actions.cancel') }}</button>
                            <button class="bg-gray-200   transition-colors text-gray-600 px-8 font-medium py-3.5 rounded-xl " :disabled="!isBtnDisabled || isDeleteLoading" 
                            :class="{'pointer-events-none': !isBtnDisabled || isDeleteLoading, 'btn-red': isBtnDisabled}" @click="$emit('handle-delete',field)">
                            {{ $t('actions.delete') }}
                            </button>

                        </div>
                    </div>
                </div>  
                <div class="max-h-full w-full max-w-md   overflow-y-auto flex flex-col" v-if="showDetailsPanel">
                    <div class="bg-primary p-2 rounded-t-lg text-white-text font-semibold flex items-center justify-between">
                        <div class="capitalize">{{activeDetail.label}} List</div>
                        <button class="bg-white cursor-pointer rounded-full h-6 w-6 justify-center items-center flex" @click="closeDetailsPanel()">
                            <font-awesome-icon icon="times" class="text-gray-800" />
                        </button>
                    </div>
                    <div class="bg-card-bg rounded-b-lg flex-1 h-full p-5 flex flex-col overflow-y-auto max-w-full">
                        <div v-if="loadingLists" class="flex justify-center items-center flex-1">
                            <Loader />
                        </div>

                        <!-- <ListView v-else :showSearch="false" :listItems="filteredDetails" class="max-w-full min-h-16"   :showBorder="false" @handleFilterResult="handleFilterResult">
                            <template #item="{ item }"> -->
                            <div v-else class="flex flex-col">

                                <div class="search_container mb-4">
                                    <Input v-model="searchKeyword"  :placeholder="$t('pages.field_table.search')" icon="search" />
                                </div>
                                <div class="bg-gray-100 cursor-pointer mb-2  max-w-full py-2 px-4 w-full truncate rounded-lg hover:bg-blue-50 hover:text-primary" v-for="item in filteredDetails" :key="item.name" @click="detailClicked(item)">
                                    {{item.name}}
                                </div>
                            </div>
                            <!-- </template>
                        </ListView> -->
                        
                    </div>
                </div>
                <!-- <div v-if="activeElement" class="max-h-full overflow-y-auto flex flex-col" style="width: 400px; height: 460px">
                    <div class="bg-primary p-2 rounded-t-lg text-white-text font-semibold flex items-center justify-between">{{ listType.permissionModalTitle || "" }}</div>
                    <div class="bg- p-2 flex-1 h-full overflow-y-auto scroll-bar">
                        <BlockAndFieldPermissions :allRoles="allRoles" :isLoading="loadingStates.roles" @selectAllRoles="selectAllRoles($event)" @selectPermission="selectPermission($event)" />
                        <ReportVisibility @selectReportVisibility="selectReportVisibility($event)" :isLoading="loadingStates.visibility" :allReportsRoles="allReportsRoles" :value="roles_visibility" />
                    </div>
                    <div class="bg- flex justify-end w-full px-2 pb-2 rounded-b-lg">
                        <Button @click="handleSubmit()" :disabled="isButtonDisabled" text="Add" type="primary" class="btn-lg" />
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
// import ListView from "@shared/components/list-view";
import Loader from "@shared/components/loader"
import Input from "@shared/input"
export default {
    data() {
        return {
            confirmDelete: 'no',
            confirmInput: '',
            activeDetail: null,
            showDetailsPanel: false,
            searchKeyword: ""
        }
    },
    props: {
        open: {
            default: false,
            type: Boolean,
        },
        loadingLists: {
            type: Boolean,
            default: false,
        },
        isDeleteLoading: {
            type: Boolean,
            default: false,
        },
        field: {
            type: Object,
            default: () => {},
            required: true
        },
        usedInDetails: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        usedInDetailsFiltered() {
            return this.usedInDetails.filter(ele => ele.count > 0)
        },
        isBtnDisabled() {
            return this.confirmInput.toLowerCase() === 'yes'
        },
        filteredDetails() {
            if (this.activeDetail && this.activeDetail.details) {
                return this.activeDetail.details.filter(ele => ele.name.toLowerCase().includes(this.searchKeyword.toLowerCase()))
            } else return []
        }
    },
    components: {
        // ListView,
        Loader,Input
    },
    methods: {
        handleFilterResult(str) {
            this.searchKeyword = str || ""
        },
        closeModal() {
            // this.handleSelect(this.activeElement);
            this.confirmInput  = ''
            this.confirmDelete = 'no'
            this.closeDetailsPanel()
            this.$emit("close");
        },
        closeDetailsPanel() {
            this.searchKeyword = ''
            this.activeDetail = null 
            this.showDetailsPanel = false 
        },
        showDetails(used) {
            this.searchKeyword = ''

            this.activeDetail = used 
            this.showDetailsPanel = true 
            console.log('used :>> ', used);
        },
        // async fetchDetails(){
        //     try {

        //         const { data } = axios(`tenant-check/4406b6b1-a626-4fa5-ba4b-a0f8ddc8bafb/b7777865-8d03-4002-8014-4b88d1f2a05c/packages`)
        //     } catch (error) {
        //         console.log('error :>> ', error);
        //     }
        // }
        async detailClicked(item){
            const link = await this.$router.resolve({name: item.route, params:{ [item.routeParams || 'id']: item.id}})
            console.log('link :>> ', link);
            window.open(link.href, '_blank')
        }
        
    }

}
</script>

<style lang="scss" scoped>
.addblocktosection {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}
.btn-red{
    background: #ff7777;
    color: #fff;
    &:hover {
        background: #f17474;

    }
}
</style>